@import "../../_variables";

.profile-notifications {
  width: 100%;
  border: 1px solid var(--bd-clr-primary);
  border-radius: 1.6rem;
  padding: 3rem;

  @media screen and (max-width: $breakpoint-sm) {
    border: none;
    position: fixed;
  }

  &__title {
    color: #999999;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  &__header {
    margin: 1rem 0 2rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e4e7e6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    color: var(--clr-primary);

    &__head {
      margin-left: auto;
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      width: 4.4rem;
      height: 4.4rem;
      border: 1px solid var(--clr-lightgray);
      border-radius: 1rem;
    }
  }

  &__close-btn {
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background-color: var(--clr-lightgray);
    border: 0;
    border-radius: 1rem;
    padding: 1rem;
  }

  &__tasks {
    &__items {
      list-style-type: none;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      @media screen and (max-width: $breakpoint-xs) {
        grid-template-columns: repeat(1, 1fr);
      }

      li {
        display: flex;
        font-size: 1.4rem;
        border: 1px solid var(--bd-clr-primary);
        padding: 1rem;
        border-radius: 1rem;

        img {
          margin-right: 1rem;
        }
      }
    }
  }
}
