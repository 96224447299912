@import "../_variables";

.setup-fields {
  display: flex;
  flex-direction: column;
  background-color: var(--clr-white);
  padding: 2rem;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  height:100vh;
  overflow:auto;

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2rem;
  }

  &__selectAll {
    display: flex;
    align-items: center;
  }

  &__selectAllCheckbox {
    width: 1.6rem;
    height: 1.6rem;
    opacity: 0.2;
    margin-right: 0.8rem;
    cursor: pointer;
  }

  &__selectAllText {
    text-transform: uppercase;
    color: rgba(153, 153, 153, 1);
    padding-left: 1.1rem;
    font-weight: 500;
    font-size: 1.4rem;
    user-select: none;
  }

  &__addFieldBtn {
    margin-top: 1.1rem;
    border: 1px solid #538270;
    padding: 1.2rem 3rem;
    background-color: transparent;
    border-radius: 1.4rem;
    cursor: pointer;
  }

  &__formContainer{
    margin-bottom: 2rem;
  }

  &__form {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $breakpoint-xs) {
      margin-bottom: 2rem;
    }

    .single-field {
      display: flex;
      border: 1px solid #e3e7eb;
      padding: 1rem;
      border-radius: 1.4rem;
      width: 93%;
      margin-left: 4rem;
      

      > div {
        width: 100%;
      }

      &__is-closed {
        display: none;
      }

      &__is-opened {
        display: block;
        border-top: 1px solid #e3e7eb;
        padding: 3rem;
        @media screen and ( max-width: $breakpoint-xs ) {
          padding: 0;
        }
      }

      &__checkbox {
        position: absolute;
        opacity: 0;
        z-index: 1;
        left: 0;
        top: 2rem;
        width: 4rem;
        height: 2rem;

        & + svg {
          margin-right: 2rem;
          top: 4rem;
          height: fit-content;

          rect {
            fill: var(--clr-white);
            stroke: var(--bd-clr-primary);
          }

          path {
            display: none;
            stroke: var(--clr-primary);
          }
        }

        &:checked {
          & + svg {
            rect {
              fill: var(--clr-lightgreen);
            }
            path {
              display: block;
            }
          }
        }
      }

      &__delete {
        cursor: pointer;
        width: 3rem;
        margin: 1rem 1.5rem;
        height: fit-content;
        padding-right: 5px;
      }

      &__accordion {
        padding: 1.5rem;
        border: 1px solid #e3e7eb;
        border-radius: 1.4rem;
        width: 63rem;
        padding: 2rem 0;

        &-heading {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h2 {
            font-size: 1.6rem;
            font-weight: 600;
          }

          button {
            background: transparent;
            border: none;
            cursor: pointer;

            img {
              width: 1.5rem;
            }
          }
        }
      }
      &__heading-controls {
        display: flex;
        align-items: center;
        gap: 0.8rem;
      }
      &__delete {
        cursor: pointer;
      }
    }
  }

  &__description {
    color: var(--text-clr);
    opacity: 0.5;
    font-size: 1.6rem;
    padding: 3rem 4rem;
  }

  &__importButton {
    margin-top: auto;
    align-self: center;
    width: 30rem;
    border: none;
    border-radius: 10px;
    background: linear-gradient(
      to bottom,
      rgba(160, 186, 176, 1),
      rgba(66, 118, 98, 1)
    );
    color: white;
    height: 47px;
    font-size: 16px;
    padding: 1rem;
  }
}
