@import "../../_variables";

.main {
  --gap: 2.3rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: max-content auto;
  align-items: flex-start;
  gap: var(--gap);

  @media screen and (max-width: $breakpoint-xl) {
    & > *:not(:first-child) {
      grid-column: span 6 !important;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    & > *:not(:first-child) {
      grid-column: span 12 !important;
    }

    &__weather__section {
      padding: 0.8rem;
      border-radius: 1.2rem;
    }

    &__map__section {
      height: 30rem;
      border-radius: 1.2rem !important;
    }
  }

  &__first-col {
    grid-column: span 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--gap);

    
  }

  &__second-col {
    grid-column: span 8;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--gap);
  }

  &__fields {
    container-name: fields;
    container-type: inline-size;

    @media screen and (max-width: $breakpoint-xs) {
      &__section {
        grid-row: 3;
        width: calc(100% + 1.6rem);
      }
    }

    .field {
      &__media {
        margin-bottom: 1rem;
        border-radius: 1.2rem;
        overflow: hidden;
      }

      &__title {
        display: block;
        margin-bottom: 0.2em;
        font-size: var(--fs-xxs);
        font-weight: var(--fw-500);
        line-height: 1.5;
        color: var(--text-clr);
      }

      &__subtitle {
        display: block;
        font-size: var(--fs-xxs);
        font-weight: var(--fw-500);
        line-height: 1.5;
        color: color-mix(in srgb, var(--text-clr) 50%, var(--clr-white) 50%);
      }
    }

    @container fields (max-width: 78.5rem) {
      &__slider {
        margin-right: calc(-1 * var(--spacing-md));
      }
    }

    @media screen and (max-width: $breakpoint-xs) {
      &__slider {
        margin-right: 0;
        padding-right: 1.6rem;
      }
    }
  }
}

.success-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  gap: var(--spacing-md);

  @media screen and (max-width: $breakpoint-sm) {
    margin: 22rem auto;
  }

  img {
    width: 5rem;
    height: 5rem;
    margin-top: -2.5rem;
  }

  .success-modal__title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    margin: 0;
  }

  .success-modal__message {
    font-size: 1.2rem;
    color: #4a4a4a;
    font-weight: 400;

    span {
      font-weight: 600;
      color: var(--clr-primary);
    }
  }
}

.success-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  gap: var(--spacing-md);

  @media screen and (max-width: $breakpoint-sm) {
    margin: 22rem auto;
  }

  img {
    width: 5rem;
    height: 5rem;
    margin-top: -2.5rem;
  }

  .success-modal__title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    margin: 0;
  }

  .success-modal__message {
    font-size: 1.2rem;
    color: #4a4a4a;
    font-weight: 400;

    span {
      font-weight: 600;
      color: var(--clr-primary);
    }
  }
}



.myFieldsModal {
  @media screen and (max-width: 768px) {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    max-width: 100vw !important;
    margin: 0 !important;
    border-radius: 0 !important;

    .modal-content {
      padding: 1rem;
    }
  }
}


.myFieldsModal {
  @media screen and (max-width: 768px) {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    max-width: 100vw !important;
    margin: 0 !important;
    border-radius: 0 !important;

    .modal-content {
      padding: 1rem;
    }
  }
}