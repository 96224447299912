.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: var(--clr-lightgray);
  border-radius: 50%;

  &__link{
    color: var(--clr-primary);
  }

  &__info {
    position: static;
    border: 0;
    cursor: pointer;
  }

  &__label {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: var(--fw-500);
    color: var(--clr-white);
    background-color: var(--alert-clr);
    border-radius: 50%;
    z-index: 100;
  }

  &__sidemenu {
    position: fixed;
  top: 1.6rem;
  right: 1.6rem;
  padding: 2.5rem 3rem;
  width: 35rem;
  height: calc(100vh - 3.2rem); // changed from min-height to height
  background-color: var(--clr-lightgray);
  border-top-left-radius: 1.6rem;
  border-bottom-left-radius: 1.6rem;
  transform: translateX(100%);
  opacity: 0;
  z-index: 10000;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  overflow-y: scroll;
  scrollbar-width: none;

  &--is-open {
    transform: translateX(0);
    opacity: 1;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--clr-lightgray);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--clr-primary);
    border-radius: 5px;
  }

  &__heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__icon {
    padding: 0.7rem;
    background-color: var(--clr-white);
    border-radius: 50%;
  }

  &__title {
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: var(--fw-700);
    color: var(--clr-primary);
  }

  &__close-btn {
    margin-left: auto;
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.2rem;
    font-weight: var(--fw-400);
    line-height: 3.5rem;
    color: var(--clr-primary);
    background-color: var(--clr-white);
    border: 1px solid var(--bd-clr-primary);
    border-radius: 1rem;
    cursor: pointer;
    }
  }

  &__list {
    list-style-type: none;

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1.5rem;
      padding: 1rem;
      background-color: var(--clr-white);
      border-radius: 1rem;
      &--unviewed{
      background-color: var(--bd-clr-primary);
    }
    }

    &__title {
      font-size: var(--fs-xxs);
      font-weight: var(--fw-500);
      line-height: 1.5;
      color: var(--text-clr);
    }
  }
}

.bold{
  font-weight:bold;
}
