@import "../../_variables";

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  @media screen and (max-width: $breakpoint-xs) {
    align-items: stretch;
    // background: none !important;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #427662, transparent);
    pointer-events: none;

    @media screen and (max-width: $breakpoint-xs) {
      // background: none;
    }
  }

  &__section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8.7rem;
    padding: var(--section-pd);
    width: 100%;

    @media screen and (max-width: $breakpoint-xs) {
      --section-pd: var(--spacing-sm) var(--spacing-md);

      flex-direction: column;
      align-items: center;
      gap: 5rem;
      padding-top: var(--spacing-lg);
    }
  }

  &__logo {
    margin-bottom: var(--section-xs);
    width: 100%;
    max-width: 8rem;
  }

  &__heading {
    width: 100%;
    max-width: 39.5rem;

    @media screen and (max-width: $breakpoint-xs) {
      text-align: center;
    }

    &__title,
    &__subtitle {
      color: var(--clr-white);

      @media screen and (max-width: $breakpoint-xs) {
        // color: var(--font-color-primary);
      }
    }
  }

  &__login {
    padding: 4rem 3rem;
    width: 100%;
    max-width: 41.8rem;
    background-color: var(--clr-white);
    border-radius: 1.2rem;

    @media screen and (max-width: $breakpoint-xs) {
      // padding: 0;
    }
  }

  .form__group {
    &--description {
      color: var(--clr-black);  
    }
  }
}



