@import "../../_variables";

.profile {
  text-align: left;
  padding: 2rem 2rem 0 0;
  border-bottom: 1px solid var(--bd-clr-primary);


  @media screen and (max-width: $breakpoint-sm) {
    width: 100vw;
  }

  &__heading {
    font-size: 1.2rem;
    color: #999999;
    margin-bottom: 0.5rem;
  }

  &__list {
    list-style-type: none;
    margin-bottom: 1rem;

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 23rem;
      font-size: 1.4rem;
      padding: 1.5rem 1rem;
      cursor: pointer;
      color: inherit;
      text-decoration: none;
      border-radius: 1rem;
      overflow: hidden;

      @media screen and (max-width:$breakpoint-sm) {
        width: 100%;
      }

      img {
        padding-right: 1rem;
        filter: grayscale(1);
      }

      &--is-active {
        background-color: #4276621a;
        color: var(--clr-primary);
        border-radius: 1rem;
      }
      span {
        white-space: nowrap;
        margin-left: auto;
        font-size: 1rem;
        background-color: white;
        padding: 0.4rem 0.8rem;
        border-radius: 2rem;
        color: var(--clr-primary);
        font-weight: bold;
      }
    }
  }

  &:last-of-type {
    border: none;
  }

  &__logout {
    padding: 2rem 0 0 1rem;
    border-top: 1px solid var(--bd-clr-primary);

    &-link {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 1.4rem;
      text-decoration: none;

      img {
        padding-right: 1rem;
      }
    }
  }
}

.toggle {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.profile-overlay-content {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  width: 80%;
  max-width: 500px;
  transition: transform 0.3s ease-in-out;

  button {
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    background-color: #427662;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
  }
}

