.filterComponent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  

  .task-list__checkbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.8rem;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 1;

      & + svg {
        rect {
          fill: var(--clr-white);
          stroke: var(--bd-clr-primary);
          transition: fill 0.2s ease, stroke 0.2s ease;
        }

        path {
          display: none;
          stroke: var(--clr-primary);
        }
      }

      &:checked {
        & + svg {
          rect {
            fill: var(--clr-lightgreen); 
          }
          path {
            display: block;
          }
        }
      }
    }

    svg {
      width: 24px;
      height: 24px;
      display: block;
    }

    label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      font-size: var(--fs-xxs);
      font-weight: var(--fw-400);
      color: var(--text-clr);
      user-select: none;
      transition: color 0.2s ease;

      &:hover {
        color: var(--clr-primary); 
      }
    }
  }

  .clearFiltersButton{
    padding: 1rem 3rem;
    border: 1px solid var(--clr-primary);
    background-color: transparent;
    border-radius: 0.5rem;
  }
}
