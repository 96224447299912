.slider {
  container-name: slider;
  container-type: inline-size;
  overflow: auto;

  &__inner {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    gap: 1.2rem;
    min-width: 78.5rem;
    & > * {
      flex: 1;
    }
  }

  @container slider (max-width: 78.5rem) {
    &__inner {
      padding-bottom: 1.5rem;
    }
  }
}
