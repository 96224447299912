.task-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.25rem;
  list-style-type: none;
  width: 100%;

  &__item {
    display: flex;
    justify-content: stretch;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    background-color: color-mix(
      in srgb,
      var(--clr-lightgray) 50%,
      var(--clr-white) 50%
    );
    border-radius: 0.7rem;
    transition: background-color 0.3s ease, opacity 1s ease;

    &--inProgress {
      background-color: var(--clr-lightgreen);
    }

    &--completed {
      opacity: 0.6;
      background-color: var(--clr-lightgray);
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 1.15rem;
    flex: 0 0 auto;
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    padding: 1.5rem;
    border-radius: 1rem;

    &--inProgress {
      background-color: color-mix(
        in srgb,
        var(--clr-primary) 20%,
        var(--clr-white) 80%
      );
    }

    &--overdue {
      background-color: color-mix(
        in srgb,
        var(--clr-yellow) 20%,
        var(--clr-white) 80%
      );
    }

    &--completed {
      opacity: 0.6;
      background-color: var(--clr-lightgray);
      color: var(--clr-lightgray);
      pointer-events: none;
    }
  }

  &__checkbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 2.4rem;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 1;

      & + svg {
        rect {
          fill: var(--clr-white);
          stroke: var(--bd-clr-primary);
        }

        path {
          display: none;
          stroke: var(--clr-primary);
        }
      }

      &:checked {
        & + svg {
          rect {
            fill: var(--clr-lightgreen);
          }
          path {
            display: block;
          }
        }
      }
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1;

    &__title {
      font-size: var(--fs-xxs);
      font-weight: var(--fw-500);
      line-height: 1.5;
      color: var(--text-clr);
    }

    &__subtitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.5;
      font-weight: var(--fw-400);
      color: var(--text-clr);

      img {
        margin-right: 0.5rem;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;

    span {
      font-size: var(--fs-xxs);
      font-weight: var(--fw-400);
      line-height: 1.5;
      white-space: nowrap;
      color: #7b8794;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    background-color: transparent;
    border-color: color-mix(in srgb, var(--clr-black) 10%, transparent 90%);
    border-radius: 1rem;
    cursor: pointer;
  }

  &__status {
    width: 0.4rem;
    height: 4.5rem;
    margin-right: 0.5rem;
    border-radius: 1rem;
    background-color: var(--clr-primary);

    &--inProgress {
      background-color: var(--clr-primary);
    }

    &--overdue {
      background-color: var(--alert-clr);
    }

    &--completed {
      background-color: var(--clr-lightred);
    }

    &--upcoming {
      background-color: var(--clr-blue);
    }
  }
}
