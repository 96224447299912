.cropZoneDetailsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .cropZoneDetailBox {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border: 1px solid var(--bd-clr-primary);
    border-radius: 0.8rem;
    height: 78px;
  
    .detailLabel {
      font-size: 12px;
      color: #999999;
      margin-bottom: 0.5rem;
    }
  
    .detailValue {
      font-size: 14px;
      color: var(--clr-black);
      font-weight: 500;
      flex-grow: 1;
    }

    .arrowRight {
        position: absolute;
        right: 1rem;
        top: 55%;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
  }
  