.main {
  background-color: #fff;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.mobileHeaderWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .subheading {
    margin-bottom: 10px;
  }

  .mobileFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.subheading {
  font-size: 18px;
  font-weight: 500;
  color: #999;
  margin: 0;
  flex-grow: 1;
}

.paginationText {
  font-size: 16px;
  font-weight: 500;
  color: #999;
  margin-left: 8px;
  margin-right: 5px;
  white-space: nowrap;
}

.pagination{
  padding-left: 3rem;
}

.divider {
  border: none;
  border-top: 1px solid var(--clr-lightgreen);
  margin: 0;
}
