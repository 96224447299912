@import "../../_variables";

.new-user {
  width: 100%;
  border: 1px solid var(--bd-clr-primary);
  border-radius: 1.6rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  @media screen and (max-width: $breakpoint-sm) {
    position: absolute;
    border: none;
    height: 100%;
    width: 100%;
    border-radius: 0;
  }

  &__header {
    margin: 0rem 0 2rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e4e7e6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    color: var(--clr-primary);

    &__head {
      margin-left: auto;
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      width: 4.4rem;
      height: 4.4rem;
      border: 1px solid var(--clr-lightgray);
      border-radius: 1rem;
    }
  }

  &__close-btn {
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background-color: var(--clr-lightgray);
    border: 0;
    border-radius: 1rem;
    padding: 1rem;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    @media screen and (max-width: $breakpoint-xs) {
      grid-template-columns: repeat(1, 1fr);
    }

    label {
      color: #999999;
      font-size: 1.6rem;
    }

    input {
      padding: 1.4rem;
      border: 1px solid #e3e7eb;
      border-radius: 1rem;
      font-size: 1.6rem;
    }

    input:disabled {
      background-color: #f3f3f3;
    }
  }

  .form__input {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }

  .form__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 2rem;

    @media screen and (max-width:$breakpoint-sm) {
      margin-top: auto;
    }

    button {
      width: 100%;
      height: 100%;
      padding: 0.8rem;
    }
  }
}
