@import "../../_variables";

.custom-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: repeat(1fr, 7);
  gap: var(--form-gr-mb);

  & label {
    text-transform: uppercase;
    color: #999999;

    &::after {
      content: " *";
      margin-left: 0.2rem;
      font-size: 1.5rem;
    }
  }

  &__error-container {
    background-color: #fff5f5;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    margin-bottom: 1rem;
    grid-column: span 2;

    &__error-message {
      color: #ff4d4d;
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0;
      display: flex;
      align-items: center;
      gap: var(--spacing-sm);
    }
  }

  input[type="date"] {
    color: #999999;
  }

  &__button {
    grid-column: span 2;
    justify-self: center;
    align-self: center;
    width: 50%;
  }

  @media screen and (max-width: $breakpoint-xs) {
    grid-template-columns: 1fr;
    grid-auto-rows: repeat(1fr, auto);

    & > * {
      grid-column: span 2 !important;
    }

    &__textarea {
      display: grid;
      grid-template-columns: 1fr;

      textarea {
        display: block;
      }

      input[type="date"] {
        grid-column: span 1;
        width: 100%;
      }

      & > input[type="date"] {
        margin-top: var(--form-gr-mb);
      }
    }
  }
}

.input-error {
  border: 0.2rem solid #db2d16 !important ;

  &:not(input, select, textarea) {
    border: none !important;
  }
}
