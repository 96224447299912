.weather {
  position: static;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1rem;
  container-name: weather;
  container-type: inline-size;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, var(--clr-lightgray), var(--clr-white));
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }

  &__temperature {
    font-size: 3.2rem;
    font-weight: var(--fw-500);
    line-height: 1.5;
    color: var(--text-clr);
  }

  &__comment {
    width: 100%;
    font-size: var(--fs-xxs);
    font-weight: var(--fw-400);
    line-height: 1.5;
    color: var(--text-clr);
  }

  &__icon {
    justify-self: end;
    max-width: 100%;
    z-index: 100;
  }

  &__additional {
    grid-column: span 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.2rem;
    width: 100%;
    background-color: var(--clr-white);
    border-radius: 1.2rem;

    &__item {
      text-align: center;
    }

    &__title {
      margin-bottom: 0.2em;
      font-size: var(--fs-xs);
      font-weight: var(--fw-500);
      line-height: 1.5;
      color: var(--text-clr);
    }

    &__subtitle {
      font-size: var(--fs-xxs);
      font-weight: var(--fw-400);
      line-height: 1.5;
      color: color-mix(in srgb, var(--text-clr) 50%, var(--clr-white) 50%);
    }
  }

  @container weather (max-width: 37.5rem) {
    &__info {
      grid-column: span 2;
      position: static;
      justify-content: center;
      gap: 0.5rem;
    }

    &__location {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__comment {
      text-align: center;
    }

    &__icon {
      grid-column: span 2;
      order: -1;
      justify-self: center;
      align-self: center;
      max-width: 10rem;
    }
  }
}
