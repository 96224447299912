@import "../../_variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 350px;
  text-align: center;
}

.header {
  color: var(--clr-black);
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 10px;

  .checkIcon {
    font-size: 30px;
  }
}

.subheader {
  color: var(--clr-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.tasksList {
  margin: 20px 0;
}

.task {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 340px;
  background-color: var(--clr-white);
  border: 1px solid var(--clr-lightgreen);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;

  .taskName {
    font-weight: 500;
    font-size: 16px;
    line-height: 20.83px;
  }

  .dueDate {
    font-size: 12px;
    margin-left: 10px;
    margin-right: auto;
    color: #999999;
  }

  .acceptedLabel {
    background-color: var(--clr-lightgreen);
    color: var(--clr-primary);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: bold;
    margin-left: auto;
  }
}

.gotItButton {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.subheader:disabled {
  color: var(--clr-disabled);
}



@media screen and (max-width: $breakpoint-xs ) {
  .container {
    width: 280px;
    padding: 0.5rem;
  }

  .header {
    font-size: 18px;
  }

  .subheader {
    font-size: 14px;
  }

  .task {
    width: 300px;
    height: 70px;
  }

  .taskName, .dueDate, .acceptedLabel {
    font-size: 12px;
  }

}