@import "../../_variables";

.modal {
  padding: 2.5rem;
  max-height: 80vh;
  background-color: var(--clr-white);
  border-radius: 1.6rem;
  overflow: auto;

  &--md {
    width: 90vw;
    max-width: 42rem;
  }

  &--lg {
    width: 90vw;
    max-width: 81.5rem;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-md);
    margin-bottom: 3rem;

    @media (max-width: $breakpoint-xs) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      margin-bottom: 0.4rem;

      &__content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
      }

      &__close {
        align-self: flex-end;
        margin-top: 1rem;
        margin-right: 0.7rem;
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.4rem;
      height: 4.4rem;
      border: 1px solid var(--bd-clr-primary);
      border-radius: 1rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.1rem;
    }

    &__title {
      font-size: 1.8rem;
      font-weight: var(--fw-700);
      line-height: 1.5;
      color: var(--clr-primary);
    }

    &__subtitle {
      font-size: 1.6rem;
      font-weight: var(--fw-400);
      line-height: 1.5;
      color: color-mix(in srgb, var(--text-clr) 50%, var(--clr-white) 50%);
    }

    &__actions {
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing-md);
      margin-left: auto;
    }

    &__action-button {
      padding: 0.8rem 3rem;
      font-size: var(--fs-xxs);
      font-weight: var(--fw-700);
      line-height: 1.5;
      background-color: transparent;
      border: 1px solid var(--clr-primary);
      border-radius: 1rem;

      &:hover {
        cursor: pointer;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.5rem;
      height: 3.5rem;
      background-color: var(--clr-lightgray);
      border: 0;
      border-radius: 1rem;
      cursor: pointer;
    }
  }

  &__actionButton_mobile{
    @media (max-width: $breakpoint-xs) {
      display: block;
      padding: 1rem;
    }
  }
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color-mix(in srgb, var(--clr-black) 50%, transparent 50%);
  backdrop-filter: blur(10px);
  z-index: 10000;
}

