@import "../_variables";

.form {
  &--inline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--form-gr-mb);

    & > * {
      flex: 1;
      margin-bottom: 0 !important;
    }
  }

  &__group {
    margin-bottom: var(--form-gr-mb);

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: var(--form-gr-mb);

      & > * {
        margin-bottom: 0 !important;
        flex: 1;
      }

      @media screen and (max-width: $breakpoint-xs) {
        flex-direction: column;

        & > * {
          width: 100%;
        }
      }
    }
  }

  &__input,
  &__textarea,
  &__select {
    margin-bottom: var(--input-mb);

    &:last-child {
      margin-bottom: 0;
    }

    label {
      display: inline-block;
      margin-bottom: var(--label-mb);
      // font-size: var(--fs-xxs);
    }

    input,
    textarea,
    select {
      display: block;
      padding: var(--input-pd);
      width: 100%;
      font-size: var(--fs-xxs);
      font-family: var(--ff);
      line-height: var(--lh-md);
      color: var(--text-clr);
      border: 1px solid var(--bd-clr-primary);
      border-radius: var(--form-el-br);

      // @media screen and (max-width: $breakpoint-xs) {
      //   width: max-content;
      // }

      &::placeholder {
        color: color-mix(in srgb, var(--text-clr) 20%, var(--clr-white) 80%);
      }
    }

    &--inline {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacing-xs);

      label {
        flex-basis: 15rem;
        margin: 0;

        & + * {
          flex: 1;
        }
      }
    }

    &--error input,
    &--error textarea
    {
      border: 0.2rem solid var(--alert-clr);
    }

    &--error select {
      border: 0.2rem solid var(--alert-clr);
    }

    &__date-wrapper {

      &:after {
        position: absolute;
        top: 1px;
        right: 4.5rem;
        width: 15px;
        height: calc(100% - 2px);
        background-color: var(--clr-white);
        border-radius: var(--form-el-br);
        z-index: 2;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 1px;
        width: 5rem;
        height: calc(100% - 2px);
        background-image: url("../assets/icons/calendar.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-color: var(--clr-lightgray);
        border-radius: 1rem;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none;
      }
    }

    textarea {
      min-height: 10rem;
      resize: vertical;
    }

    &--with-icon {
      img {
        position: absolute;
        display: block;
        top: 50%;
        left: 1.5rem;
        z-index: 1000;
        transform: translateY(-50%);
      }

      select {
        padding-left: calc(1.5rem + var(--input-pd) * 2);
      }
    }
  }

  &__check-button {
    margin-bottom: var(--input-mb);

    input {
      display: none;

      &:checked + label {
        color: var(--clr-primary);
        background-color: color-mix(
          in srgb,
          var(--clr-primary) 20%,
          var(--clr-white) 80%
        );
        border-color: color-mix(
          in srgb,
          var(--clr-primary) 20%,
          var(--clr-white) 80%
        );

        img {
          filter: blur(0);
          opacity: 1;
        }
      }
    }

    label {
      display: block;
      padding: var(--input-pd);
      padding-left: calc(1.5rem + var(--input-pd) * 2.5);
      width: fit-content;
      font-size: var(--fs-xs);
      font-family: var(--ff);
      font-weight: var(--fw-500);
      line-height: var(--lh-md);
      color: var(--text-clr);
      border: 1px solid var(--bd-clr-primary);
      border-radius: var(--form-el-br);
      cursor: pointer;
      user-select: none;

      @media screen and (max-width: $breakpoint-xs) {
        width: 100%;
      }

      img {
        position: absolute;
        top: 50%;
        left: 1.5rem;
        width: 21px;
        transform: translateY(-50%);
        filter: grayscale(1);
        opacity: 0.5;
      }
    }
  }

  &__checkbox-dropdown {
    border: 1px solid var(--bd-clr-primary);
    border-radius: var(--form-el-br);

    &--active {
      .form__checkbox-dropdown__content {
        transform: scaleY(1);
      }
    }

    &__heading {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      padding: var(--input-pd);
      width: 100%;
      min-width: 12rem;
      background-color: transparent;
      border: 0;
      cursor: pointer;

      img {
        margin-left: auto;
      }
    }

    &__title {
      font-size: 1.6rem;
      font-weight: var(--fw-500);
      line-height: 1.5;
    }

    &__status {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.8rem;
      height: 1.8rem;
      font-size: 1rem;
      font-weight: var(--fw-500);
      line-height: 1.5;
      color: var(--clr-primary);
      background-color: color-mix(
        in srgb,
        var(--clr-primary) 20%,
        var(--clr-white) 80%
      );
      border-radius: 50%;
    }

    &__content {
      position: absolute;
      top: calc(100% - 1rem);
      left: -1px;
      padding: 1.2rem;
      padding-top: 2.2rem;
      width: calc(100% + 2px);
      max-height: 500px;
      list-style-type: none;
      background-color: var(--clr-white);
      border: 1px solid var(--bd-clr-primary);
      border-top: 0;
      border-bottom-left-radius: 0.7rem;
      border-bottom-right-radius: 0.7rem;
      transform: scaleY(0);
      transform-origin: top center;
      transition: transform 0.2s ease-in-out;
      z-index: 1000;
    }

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      font-size: var(--fs-xxs);
      font-weight: var(--fw-500);
      line-height: 1.5;
      user-select: none;

      &:first-child {
        font-size: 1rem;
        font-weight: var(--fw-500);
        line-height: 1.5;
        text-transform: uppercase;
        color: var(--clr-primary);
      }

      &:last-child {
        margin-bottom: 0;
      }

      label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 1;

        & + svg {
          flex-shrink: 0;
          width: 1.6rem;
          height: 1.6rem;
          // border: 1px solid royalblue;

          rect {
            fill: var(--clr-white);
            stroke: var(--bd-clr-primary);
            stroke-width: 5px;
          }

          path {
            display: none;
            stroke: var(--clr-primary);
          }
        }

        &:checked {
          & + svg {
            rect {
              fill: var(--clr-lightgreen);
            }
            path {
              display: block;
            }
          }
        }
      }
    }
  }

  &__button {
    display: block;
    margin-bottom: var(--button-mb);
    padding: var(--button-pd);
    width: 100%;
    font-size: var(--fs-xs);
    font-family: var(--ff);
    font-weight: var(--fw-700);
    color: var(--clr-white);
    background-color: var(--clr-white);
    border: 1px solid transparent;
    border-radius: var(--form-el-br);
    z-index: 1;
    cursor: pointer;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to top,
        var(--clr-primary),
        var(--clr-secondary)
      );
      border-radius: var(--form-el-br);
      z-index: -1;
      transition: opacity 0.2s ease-in-out;
      pointer-events: none;
    }

    &:hover {
      color: var(--text-clr);
      border-color: var(--clr-secondary);

      &::before {
        opacity: 0;
      }
    }

    &--transparent {
      color: var(--text-clr);
      background: none;
      border: 1px solid var(--clr-secondary);

      &::before {
        opacity: 0;
      }
    }
  }

  &__info {
    font-size: var(--fs-xxs);
    line-height: var(--lh-md);
    text-align: center;
    color: var(--text-clr);

    &-link {
      font-weight: var(--fw-700);
      color: var(--clr-primary);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &--underline {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
