.toggleSwitch {
    cursor: pointer;
    position: absolute;
    right: 1rem;
}

.input {
    display: none;
}

.switch {
    width: 3.5rem;
    height: 2rem;
    background-color: #d9d9d9;
    border-radius: 3.2rem;
    position: relative;
    transition: background-color 0.3s ease;

    &::before {
        content: "";
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: var(--clr-white);
        top: 14%;
        left: .2rem;
        transition: transform 0.3s ease;
    }
}

.input:checked+.switch {
    background-color: var(--clr-primary);

    &::before {
        transform: translateX(1.5rem);
    }
}