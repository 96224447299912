@import "../../_variables";

.custom-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  align-items: start;

  & label {
    text-transform: uppercase;
    color: #999;
    font-size: 0.9rem;
  }

  .description-row {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: $breakpoint-xs) {
        grid-template-columns: 1fr;
    }
  }


  .done-button {
    border-color: #198754;
    color: #198754;
  }

  .cancel-button {
    border-color: #dc3545;
    color: #dc3545;
  }

  .status-group {
    display: flex;
    align-items: center;
    gap: 0.5rem; 

    select {
      flex: 1;
      margin-right: 0.5rem;
    }

    button {
      padding: 1rem 1.5rem;
      border-radius: 0.5rem;
      font-size: 0.9rem;
      font-weight: bold;
      cursor: pointer;
      background-color: white;

      &.done-button {
        border: 1px solid #198754; 
        color: #198754; 
      }

      &.cancel-button {
        border: 1px solid #dc3545; 
        color: #dc3545; 
      }
    }
  }
}
