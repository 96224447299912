.field {
  &__media {
    margin-bottom: 1rem;
    border-radius: 1.2rem;
    overflow: hidden;
  }

  &__title {
    display: block;
    margin-bottom: 0.2em;
    font-size: var(--fs-xxs);
    font-weight: var(--fw-500);
    line-height: 1.5;
    color: var(--text-clr);
  }

  &__subtitle {
    display: block;
    font-size: var(--fs-xxs);
    font-weight: var(--fw-500);
    line-height: 1.5;
    color: color-mix(in srgb, var(--text-clr) 50%, var(--clr-white) 50%);
  }
}
