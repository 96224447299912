@import "../../_variables";

.main {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: max-content;
  gap: var(--spacing-md);
}

.aside {
  grid-column: span 3;

  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xs);
    margin-bottom: var(--spacing-md);

    &__media {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      aspect-ratio: 1 / 1;
    }

    &__title {
      font-size: 1.8rem;
      font-weight: var(--fw-600);
      line-height: 1.5;
      text-align: center;
      color: var(--clr-black);
    }

    &__subtitle {
      font-size: 1.2rem;
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: center;
      text-transform: uppercase;
      color: var(--clr-black);
    }
  }

  &__nav {
    list-style-type: none;

    &__heading {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      margin-bottom: var(--spacing-sm);

      &__title {
        font-size: 1.2rem;
        font-weight: var(--fw-400);
        color: #999999;

        &--main {
          font-size: 1.6rem;
          font-weight: var(--fw-500);
          color: var(--text-clr);
        }
      }

      &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: 1px solid var(--bd-clr-primary);
        border-radius: 0.8rem;
        cursor: pointer;
      }
    }

    &__link {
      display: flex;
      font-size: 1.4rem;
      padding: 1.5rem 1rem;
      cursor: pointer;
      color: inherit;
      text-decoration: none;

      img {
        padding-right: 1rem;
        filter: grayscale(100) opacity(0.7);
      }

      &--is-active {
        color: var(--clr-primary);
        background-color: #4276621a;
        border-radius: 1rem;

        img {
          filter: grayscale(0) opacity(1);
        }
      }
    }
  }
}

.content {
  grid-column: span 9;
  padding: var(--spacing-md);
  border: 1px solid var(--bd-clr-primary);
  border-radius: 1.6rem;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-md);
    padding-bottom: var(--spacing-md);
    text-align: left;
    border-bottom: 1px solid var(--bd-clr-primary);

    button {
      background-color: transparent !important;
      border: 0 !important;
    }

    img {
      cursor: pointer;
    }

    &__title {
      color: var(--clr-primary);
      font-size: var(--fs-sm);
      font-weight: var(--fw-500);

      @media screen and (max-width: $breakpoint-xs) {
        font-size: 1.9rem;
        padding-bottom: 0.5rem;
      }
    }

    &__description {
      font-size: var(--fs-xs);
      padding: 0 2rem var(--spacing-sm);
      font-weight: 200;
      color: var(--text-clr);
    }
  }
}
