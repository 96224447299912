@import "../../_variables";

.container {
  background-color: #fff;
  width: 400px;
  border-radius: 10px;
  max-width: 350px;
  margin: 1rem auto;
  
  .error{
    background-color: rgba(255, 240, 240, 1);
    border-radius: 10px;
    padding: 10px 16px 10px 16px;
    gap: 15px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;

    .errorMessage{
      font-size: 13px;
      color: rgba(246, 28, 14, 1);
    }
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    margin-bottom: 20px;

    .icon{
      background-color: #FFF0F0;
      border-radius: 3px;
      padding-top: 2px;
    }

    .title {
      font-weight: 700;
      line-height: 29.13px;
      letter-spacing: 1%;
    }

    .closeButton {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 24px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .reasonLabel,.charLimit{
      color: #999999;
    }

    label {
      font-size: 11px;
      color: #333;

      select, textarea {
        width: 100%;
        padding: 8px;
        margin-top: 5px;
        border: 1px solid var(--clr-lightgreen);
        border-radius: 5px;
      }

      textarea {
        background-color: #FFF0F0;
        height: 91px;
      }
    }

    .submitButton {
      background-image: linear-gradient(to top, #427662 100%, #A0BAB0 100%);
      width: 250px;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 10px;
      font-size: 16px;
      cursor: pointer;
      align-self: center;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .container {
    width: 300px;
    padding-right: 1rem;
  }
  
}