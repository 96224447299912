@import "../../_variables";

.main {
  --gap: 2.3rem;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: max-content auto max-content;
  grid-auto-rows: max-content;
  align-items: flex-start;
  gap: var(--gap);
  min-height: calc(100vh - 3.2rem);

  &__first-col {
    grid-column: span 7;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--gap);
  }

  &__second-col {
    grid-column: span 5;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--gap);
  }

  &__filters {
    grid-column: span 12;
  }
}

.success-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  gap: var(--spacing-md);

  img {
    width: 5rem;
    height: 5rem;
    margin-top: -2.5rem;
  }

  .success-modal__title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    margin: 0;
  }

  .success-modal__message {
    font-size: 1.2rem;
    color: #4a4a4a;
    font-weight: 400;

    span {
      font-weight: 600;
      color: var(--clr-primary);
    }
  }
}

.drafts {
  @media screen and (max-width: $breakpoint-xs) {
    border: 1px solid #e4e7e6;
    padding: 0.8rem;
    border-radius: 1rem;
  }
}

.mobile-screen {
  display: inline;
}

.task-section {
  @media screen and (max-width: $breakpoint-xs) {
    border: 1px solid #e4e7e6;
    border-radius: 0.8rem;
    padding: 1rem 0.2rem;

    span {
      display: none;
    }

    h2 {
      font-size: 1rem;
      color: var(--text-clr);
    }
  }
}

.cancel-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  &__container {
    &__heading {
      margin-bottom: 0;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__error {
      background-color: red;
    }

    p {
      margin: 0 0 1rem;
      font-size: 1.3rem;
      color: var(--text-primary, #333);

      @media screen and (max-width: $breakpoint-xs) {
        font-size: 1.1rem;
      }
    }

    &__textarea {
      background-color: #fff0f0;
    }
  }

  &__error-container {
    background-color: #fff5f5;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    margin-bottom: 1rem;
    grid-column: span 2;

    p {
      font-size: 1.3rem;
      @media screen and (max-width: $breakpoint-xs) {
        font-size: 1.2rem;
      }
    }

    img {
      @media screen and (max-width: $breakpoint-xs) {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    &__error-message {
      color: #ff4d4d;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}
