@import "../_variables.scss";

.accordion-items {
  &__group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
    }

    &__item {
      opacity: 0.5;
      padding: 0 1.5rem;

      input {
        display: block;
        padding: 1.5rem;
        border: 1px solid #e3e7eb;
        border-radius: 1.5rem;
        margin-top: 1rem;
        width: 100%;

        &:last-child {
          margin-bottom: var(--input-mb);
        }
      }
    }
  }

  &__coordinates {
    display: flex;

    div {
      width: 100%;
    }

    img {
      height: 20rem;
      padding-right: 2rem;
    }

    span {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
    }
  }
}
