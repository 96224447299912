.heading {
  grid-column: span 12;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  width: 100%;

  & > *:last-child {
    margin-left: auto;
  }

  &__title {
    margin: 0;
    font-size: 18px;     
    font-weight: 700;
    line-height: 29.13px;
    color: var(--clr-primary);
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.4rem;
    height: 4.4rem;
    border: 1px solid var(--bd-clr-primary);
    border-radius: 1rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2.5rem;
  }
}
