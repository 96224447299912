@import "../_variables";

.heading {
  display: flex;
  justify-content: flex-start;
  gap: var(--spacing-xs);
  align-items: center;
  gap: var(--spacing-xs);
  margin-bottom: 1.6rem;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid var(--bd-clr-primary);

  @media screen and (max-width: $breakpoint-xs) {
    padding-bottom: 0;
    border: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    color: var(--clr-primary);

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 1.8rem;
    }

    span {
      margin-right: 1rem;
      padding: 0.2rem 1rem;
      font-weight: var(--fw-500);
      font-size: 1rem;
      color: var(--clr-white);
      background-color: #4276624d;
      border-radius: var(--form-section-br);
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
    color: var(--clr-primary);
    border: 1px solid var(--clr-primary);
    border-radius: 0.4rem;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    span {
      margin-right: 0.5rem;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 1.6rem;
    }
  }

  &__number {
    background-color: var(--bd-clr-primary);
    padding: 0.2rem 0.6rem;
    border-radius: 50%;
  }

  &__link {
    display: flex;
    margin-left: auto;
    margin-left: auto;
    color: var(--clr-primary);
    text-decoration: none;
    font-size: 1.4rem;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 1.6rem;
      font-weight: var(--fw-700);

      img {
        display: none;
      }
    }

    span {
      align-self: center;
      padding-right: 1rem;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    background-color: color-mix(
      in srgb,
      var(--clr-primary) 20%,
      var(--clr-white) 80%
    );
    border-radius: 0.8rem;
  }
}
