@import "../../_variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 350px;
  text-align: center;
}

.header {
  color: var(--clr-black);
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 10px;

  .checkIcon {
    font-size: 30px;
  }
}

.subheader {
  color: var(--clr-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.subtext {
  color: var(--clr-primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.gotItButton {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border: none;
  border-radius: 5px;
  margin-top: 2rem;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}
