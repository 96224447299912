.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .pagination__prev,
  .pagination__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.7rem;
    height: 3.7rem;
    border: none;
    border-radius: 0.8rem; 
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }

    svg {
      fill: none;
      stroke: var(--clr-primary);
    }
  }

  .pagination__page {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #999999; 
    font-size: 14px;

    &:hover {
      color: var(--clr-primary); 
      text-decoration: underline;
    }
  }

  .active {
    color: var(--clr-primary);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-secondary);
  }
}
