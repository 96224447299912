@import "../_variables";

.header {
  position: relative;
  padding: 1.5rem 2.5rem;
  padding-top: 3rem;
  background-color: var(--clr-lightgray);
  border-radius: 1.6rem;
  z-index: 10000;

  @media screen and (max-width: $breakpoint-xs) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    grid-column: span 12;
    grid-row: 2;
    padding: 1.3rem 2.4rem;
    border-radius: 0;
  }

  .nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;

    @media screen and (max-width: $breakpoint-xs) {
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }

    &__logo {
      max-width: 50px;

      img {
        width: 100%;
      }
    }

    &__link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-xs);
      padding: 1rem;
      font-size: 1rem;
      text-decoration: none;
      color: var(--text-clr);
      border-radius: 1rem;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

      &:hover,
      &--is-active {
        color: var(--clr-primary);
        background-color: var(--clr-white);
      }

      @media screen and (max-width: $breakpoint-xs) {
        --spacing-xs: 0.5rem;
      }

      img {
        @media screen and (max-width: $breakpoint-xs) {
          width: 1.8rem;
        }
      }
    }
  }
}
