@import "../_variables";

.heading {
  grid-column: span 12;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  width: 100%;

  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
    padding-right: 1rem;
  }



  & > *:last-child {
    margin-left: auto;
  }

   @media screen and (max-width: $breakpoint-sm) {
    &__title {
      display: none;
    }
  }

  &__title {
    margin: 0;
    font-size: 1.8rem;
    font-weight: var(--fw-700);
    line-height: 1.5;
    color: var(--clr-primary);
  }

 

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.4rem;
    height: 4.4rem;
    border: 1px solid var(--bd-clr-primary);
    border-radius: 1rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2.5rem;

    button {
      cursor: pointer;
    }
  }

  &__button {
    margin-left: auto;
    padding: 0.8rem 3rem;
    font-size: var(--fs-xxs);
    font-weight: var(--fw-700);
    line-height: 1.5;
    background-color: transparent;
    border: 1px solid var(--clr-primary);
    border-radius: 1rem;
  }
}
