@import "../../_variables";

.main {
  &__body {
    display: flex;
    margin-top: 3rem;
    height: 100%;
    height: auto;

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      margin: auto;
      padding: 0 1rem;
    }

    &__sidebar {
      padding: 0 0.5rem;
      width: 23rem;
      text-align: center;
      margin-right: 2rem;

      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
      }

      &__image {
        border-radius: 50%;

        @media screen and (max-width: $breakpoint-sm) {
          width: 20rem;
          height: 20rem;
        }
      }

      h1 {
        font-size: 1.8rem;
      }

      h2 {
        font-size: 1.2rem;
        font-weight: 300;
      }
    }
  }
}

.new-user-modal .heading .content{
  display: flex;
}

.success-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  gap: var(--spacing-md);

  @media screen and (max-width: $breakpoint-sm) {
    margin: auto;
  }

  img {
    width: 5rem;
    height: 5rem;
    margin-top: -2.5rem;
  }

  .success-modal__title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    margin: 0;
  }

  .success-modal__message {
    font-size: 1.2rem;
    color: #4a4a4a;
    font-weight: 400;

    span {
      font-weight: 600;
      color: var(--clr-primary);
    }
  }
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 100000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  padding-bottom: 3rem;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
