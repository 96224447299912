@import "../_variables";

.section {
  padding: var(--spacing-md);
  width: 100%;
  border: 1px solid var(--bd-clr-primary);
  border-radius: var(--form-section-br);
  overflow: hidden;

  @media screen and (max-width: $breakpoint-xs) {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
}
