@import "../../_variables";

.profile-languages {
  width: 100%;
  border: 1px solid var(--bd-clr-primary);
  border-radius: 1.6rem;
  padding: 3rem;

  &__title {
    color: #999999;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  &__header {
    margin: 1rem 0 2rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e4e7e6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    color: var(--clr-primary);

    &__head {
      margin-left: auto;
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      width: 4.4rem;
      height: 4.4rem;
      border: 1px solid var(--clr-lightgray);
      border-radius: 1rem;
    }
  }

  &__close-btn {
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background-color: var(--clr-lightgray);
    border: 0;
    border-radius: 1rem;
    padding: 1rem;
  }

  &__suggested {
    &__items {
      list-style-type: none;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      @media screen and (max-width: $breakpoint-xs) {
        grid-template-columns: repeat(1, 1fr);
      }

      li {
        display: flex;
        font-size: 1.4rem;
        border: 2px solid var(--clr-lightgray);
        padding: 1rem;
        border-radius: 1rem;
        font-weight: 400;
        color: var(--clr-primary);
        cursor: pointer;
        transition: border-color 0.3s ease;

        &:has(input:checked) {
          border-color: var(--clr-primary);
        }

        img {
          margin-right: 1rem;
          filter: grayscale(1);
          transition: filter 0.3s ease;
        }

        label {
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-direction: row-reverse;
          cursor: pointer;
          color: #999999;

          input {
            appearance: none;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid var(--clr-secondary);
            position: relative;
            margin-right: 1rem;
            cursor: pointer;
            transition: border-color 0.3s ease;

            &:checked {
              border-color: var(--clr-primary);
            }

            &::before {
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: transparent;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              transition: background-color 0.3s;
            }

            &:checked::before {
              background-color: var(--clr-primary);
            }
          }

          span {
            display: flex;
            align-items: center;

            img {
              margin-right: 1rem;
              transition: filter 0.3s ease;
            }

            input:not(:checked) + span {
              color: var(--clr-black);
              img {
                filter: grayscale(1);
              }
            }
          }
        }
        input:checked + span img {
          filter: none;
        }

        input:checked + span {
          color: var(--clr-primary);
        }
      }
    }
  }
}