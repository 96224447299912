.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-sm);

  &__label{
    font-size: 1.2rem;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.7rem;
    height: 3.7rem;
    background-color: var(--clr-lightgray);
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;

    &--disabled{
      background-color: var(--clr-lightgray);
      opacity: 0.5;
    }
  }
}
