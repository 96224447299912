.location {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  background-color: var(--clr-lightgray);
  border-radius: 6.4rem;
  z-index: 1000;

  &__title {
    font-size: var(--fs-xxs);
    font-weight: var(--fw-400);
    line-height: 1.5;
    color: var(--text-clr);
  }
}
