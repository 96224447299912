@import "../_variables";

.successful-import {
    background-color: var(--clr-white);
    height: 100vh;
    text-align: center;
    align-content: center;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;

    &__heading {
        border-bottom: 1px solid #F5F7FA;
        width: 42rem;
        margin: 0 auto;
        padding-bottom: 2rem;

        h2 {
            font-size: 2.2rem;
            font-weight: 500;
        }
        p {
            color:#0D181480;
            opacity: 0.5;
            font-size: var(--fs-xs);
        }
    }

    &__body {
        width: 42rem;
        margin: 0 auto;
        
        &__item {
            display: flex;
            text-align: left;
            width: 42rem;
            align-items: center;
            font-size: var(--fs-xs);
            border: 1px solid #E3E7EB;
            border-radius: 1rem;
            padding: 1rem;
            margin: 3rem auto;
            font-weight: 500;

            @media screen and (max-width: $breakpoint-xs) {
                width: 35rem;
            }

            img {
                margin-right: 2rem;
            }

        }
    }
}

.form__button{
    margin-bottom: -1.25rem;
    width: 35rem;
    
}