.section-content-group {
  margin-bottom: var(--spacing-md);

  &__heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.2rem;
      font-weight: var(--fw-500);
      line-height: 1.5;
      text-transform: uppercase;
      color: var(--clr-primary);
    }

    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-sm);
      margin-left: auto;
    }
  }
}
