@import "../../_variables";

.recommendationItem {
  border: 1px solid var(--clr-lightgreen);
  border-radius: 5px;
  padding: 2rem;

  @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-lg) {
    display: block;
  }

  &__topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobileRowOne {
    margin-bottom: 1rem;
  }

  .mobileRowTwo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .mobileRowButtons {
    display: flex;
    gap: 0.7rem;
    flex-wrap: wrap;
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
  }

  &__due {
    font-size: 14px;
    color: #777;
    margin-left: 1rem;
  }

  &__actions {
    display: flex;
    align-items: center;

    button {
      margin-left: 10px;
      cursor: pointer;
    }

    .acceptButton,
    .declineButton {
      background-color: var(--clr-white);
      color: rgba(66, 118, 98, 1);
      border: 1px solid rgba(66, 118, 98, 1);
      padding: 8px 12px;
      font-size: 14px;
      border-radius: 5px;

      &:hover {
        background-color: var(--clr-lightgrey);
      }
    }

    .declineButton {
      color: var(--alert-clr);
      border-color: var(--alert-clr);
    }

    .dropdownButton {
      background: transparent;
      border: none;
      padding: 8px;
    }
  }

  &__content {
    font-size: 14px;
    margin-top: 10px;

    .divider {
      border-top: 1px solid #ddd;
      margin: 10px 0;
    }

    .taskColumns {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .taskColumn {
        border: 1px solid rgba(227, 231, 235, 1);
        border-radius: 10px;
        flex: 1;

        .taskTable {
          width: 100%;
          border-collapse: collapse;

          .taskLabel,
          .taskValue {
            padding: 8px;
            text-align: left;
          }

          .taskLabel {
            background-color: var(--clr-disabled);
            font-weight: 600;
            color: rgba(153, 153, 153, 1);
            width: fit-content;
          }

          .taskValue {
            background-color: var(--clr-disabled);
            color: rgba(13, 24, 20, 1);
            font-weight: 500;
            width: 25%;
          }
        }

        .taskDescription {
          padding: 10px;

          .descriptionLabel {
            font-weight: 600;
            color: #444;
          }

          .descriptionValue {
            color: rgba(153, 153, 153, 1);
            font-weight: 500;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.acceptedText {
  color: var(--clr-primary);
  background-color: rgba(217, 228, 224, 1);
  font-size: 12px;
  padding: 1rem;
  border-radius: 6px;
  font-weight: 600;
  margin-left: 10px;
}

.declinedText {
  color: var(--alert-clr);
  background-color: rgba(255, 240, 240, 1);
  font-size: 12px;
  padding: 1rem;
  border-radius: 6px;
  font-weight: 600;
  margin-left: 10px;
}

@media only screen and (max-width: $breakpoint-sm) {
  .recommendationItem {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    &__topRowMobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mobileRowContent {
      flex: 1;
    }

    &__content {
      .taskColumns {
        flex-direction: column;
        width: 100%;
        .taskColumn {
          overflow-x: auto;
          .taskTable {
            min-width: 550px;
            table-layout: auto;
            width: 100%;
            border-collapse: collapse;

            .taskLabel,
            .taskValue {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .taskDescription {
            .descriptionLabel {
              color: rgba(153, 153, 153, 1);
            }
            .descriptionValue {
              color: var(--clr-black);
            }
          }
        }
      }
    }
    &__due {
      font-size: 12px;
    }
  }
  .recommendationItem__topRow {
    display: none;
  }
  .recommendationItem__due {
    color: rgba(153, 153, 153, 1);
    margin-left: 0;
    font-size: 12px;
  }

  .acceptButton,
  .declineButton {
    font-size: 12px;
    padding: 6px 10px;
  }

  .acceptButton {
    background-color: var(--clr-white);
    color: rgba(66, 118, 98, 1);
    border: 1px solid rgba(66, 118, 98, 1);
    padding: 8px 12px;
    border-radius: 5px;
  }
  .declineButton {
    background-color: var(--clr-white);
    color: rgba(219, 45, 22, 1);
    border: 1px solid rgba(219, 45, 22, 1);
    padding: 8px 12px;
    border-radius: 5px;
  }
  .dropdownButton {
    margin-left: 2rem;
    background: transparent;
    border: none;
  }
}
