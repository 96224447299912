@import "../_variables";

.setup-heading {
    display: flex;
    align-items: center;
    text-align: left;
    border-bottom: 1px solid #E4E7E6;

    .nav__logo {
    
    &.disabled {

      img {
        opacity: 0.5; 
        pointer-events: none;
      }
    }
  }

    img {
        cursor: pointer;
        transition: opacity 0.3s ease, transform 0.3s ease;
        }
    

    &__heading {
        color: var(--clr-primary);
        font-size: var(--fs-sm);
        font-weight: var(--fw-500);
        padding: var(--spacing-sm);
        padding-bottom: 3px;

        @media screen and (max-width: $breakpoint-xs) {
            font-size: 1.9rem;
            padding-bottom: 0.5rem;
        }
    }

    &__description {
        font-size: var(--fs-xs);
        padding: 0 2rem var(--spacing-sm);
        padding-left: 18px;
        font-weight: 200;
        color: rgba(13, 24, 20, 0.5);
    }
    
}